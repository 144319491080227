// Load promise polyfill for everyone so that we can load polyfills conditionally
require("es6-promise");
const polyfills = [];
const supportsEs6 = window.Symbol && Object.hasOwnProperty("assign");
if (!supportsEs6) {
    polyfills.push(import(/* webpackChunkName: "es6" */ "babel-polyfill"));
}
if (!window.fetch) {
    polyfills.push(import(/* webpackChunkName: "fetch" */ "whatwg-fetch"));
}
if (!window.Intl) {
    polyfills.push(import(/* webpackChunkName: "internationalization" */ "./polyfills/internationalization.js"));
}
Promise.all(polyfills)
    .then(() => {
    // Framework imports
    const React = require("react");
    const ReactDOM = require("react-dom");
    const { Provider } = require("react-redux");
    const { getHeaderRoutes } = require("./routers/createHeaderAppRoutes");
    const { getSelectedCityName } = require("./selectors/index");
    const createInsiderStore = require("./store").default;
    const { browserHistory, match, Router } = require("react-router");
    const getInitialState = require("./getInitialState").default;
    /**
     * This function does all the initial
     * client side scaffolding
     * 1. Create our redux store, and rehydrate it
     * 2. Use the browserHistory since we're using URLs and not hashes
     * 3. Figure out the current city of the user - this comes from the rehydrated store
     * 4. Call `match` from react-router, which will run the callback after all async routes are loaded
     * 5. Render
     * 6. Profit!
     */
    const initialiseApp = () => {
        const initialRootState = getInitialState();
        const store = createInsiderStore(initialRootState);
        const history = browserHistory;
        const city = getSelectedCityName(store.getState());
        const { pathname, search, hash } = window.location;
        const location = `${pathname}${search}${hash}`;
        const routes = getHeaderRoutes(city);
        const rootNode = document.getElementById("header-legacy");
        match({ routes, location, history }, (error, redirectLocation, renderProps) => {
            ReactDOM.hydrate(<Provider store={store}>{<Router {...renderProps}/>}</Provider>, rootNode);
        });
    };
    initialiseApp();
})
    .catch(e => console.error(e));
